import { getPriceChart } from "../core/shopdata"
import { h2Unlinked } from "../helpers/blockmaker"
export function System(){
    const priceData = [
        {title: "60分", price: "30,000"},
        {title: "90分", price: "40,000"},
        {title: "120分", price: "50,000"},
        {title: "延長 (30分)", price: "5,000"},
        {title: "写真指名", price: "2,000"}
    ]

    return(
        <main id="system">
            {h2Unlinked("料金")}
            <article className="wrapper-table">
                <img src={getPriceChart("2024-07-19T00:00:00")} alt="チューリップガールズ (雄琴店) の料金表" />
                {
                    /*
                        <table>
                            <tbody>
                                {priceData.map(p=>{
                                    return (
                                        <tr>
                                            <th>{p.title}</th>
                                            <td>{p.price}円</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    */
                }
            </article>
        </main>
    )
}

export default System